import {createRouter, createWebHistory} from 'vue-router'
import ConsumerView from '../views/ConsumerView.vue'
import BuyCouponView from "@/views/consumer/BuyCouponView";
import MerchantView from "@/views/MerchantView";
import LandingView from "@/views/LandingView";
import TellSomeoneView from "@/views/TellSomeoneView";
import RequestCouponView from "@/views/consumer/RequestCouponView";
import ViewCouponsView from "@/views/consumer/ViewCouponsView";
import ConsumerTxView from "@/views/consumer/ConsumerTxView";
import RedeemCouponView from "@/views/merchant/RedeemCouponView";
import SendCouponView from "@/views/merchant/SendCouponView";
import ScanCouponView from "@/views/merchant/ScanCouponView";
import TalkToCustomersView from "@/views/merchant/TalkToCustomersView";
import MerchantTxView from "@/views/merchant/MerchantTxView";

const routes = [
    {
        path: '/',
        component: LandingView,
    },
    {
        path: '/consumer',
        name: 'Consumer Menu',
        component: ConsumerView,
    },
    {
        path: '/merchant',
        name: 'Merchant Menu',
        component: MerchantView,
    },
    {
        path: '/consumer/buy-coupon',
        name: 'Buy a Coupon',
        component: BuyCouponView,
    },
    {
        path: '/consumer/request-coupon',
        name: 'Request a Coupon',
        component: RequestCouponView,
    },
    {
        path: '/consumer/view-coupons',
        name: 'View Coupons',
        component: ViewCouponsView,
    },
    {
        path: '/consumer/tx',
        name: 'Consumer Transactions',
        component: ConsumerTxView,
    },
    {
        path: '/merchant/redeem-coupon',
        name: 'Redeem Coupon',
        component: RedeemCouponView,
    },
    {
        path: '/merchant/send-coupon',
        name: 'Send a Coupon',
        component: SendCouponView,
    },
    {
        path: '/merchant/scan-coupon',
        name: 'Scan a Coupon',
        component: ScanCouponView,
    },
    {
        path: '/merchant/talk',
        name: 'TalkToCustomers',
        component: TalkToCustomersView,
    },
    {
        path: '/merchant/tx',
        name: 'Merchant Transactions',
        component: MerchantTxView,
    },
    {
        path: '/tell',
        name: 'Tell Someone',
        component: TellSomeoneView,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
