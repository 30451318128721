<template>
  <h2 class="pt-5 text-lg font-medium text-gray-900">Please wait while loading your profile ...</h2>
</template>

<script>

export default {
  name: 'LandingView',
}
</script>
