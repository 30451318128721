<template>
  <h2 class="text-lg font-medium text-gray-900">SupaCoupon Merchant</h2>
  <p class="mt-1 text-sm text-gray-500">Select an option from the menu below.</p>

  <ul role="list"
      class="mt-6 divide-y divide-gray-200 border-t border-b border-gray-200">
    <MenuListItem v-for="(mli, index) of menuListItems"
                  :key="index"
                  :title="mli.title"
                  :subtitle="mli.subtitle"
                  :link="mli.link"
                  :colorClass="mli.colorClass">
      <template #icon>
        <component :is="mli.icon" class="h-6 w-6 text-white"/>
      </template>
    </MenuListItem>
  </ul>

  <div class="mt-6 flex">
    <router-link to="/tell" class="text-sm font-medium text-indigo-600 hover:text-indigo-500">
      Tell someone about SupaCoupon
      <span aria-hidden="true"> &rarr;</span>
    </router-link>
  </div>
</template>

<script>
import {
  ChatBubbleBottomCenterTextIcon, PaperAirplaneIcon,
  QrCodeIcon,
  QueueListIcon,
  ShoppingCartIcon,
} from "@heroicons/vue/20/solid";
import MenuListItem from "@/components/MenuListItem";

export default {
  name: 'MerchantView',
  data() {
    return {
      menuListItems: [
        {
          title: 'Redeem a Coupon',
          link: '/merchant/redeem-coupon',
          subtitle: 'Redeem a coupon',
          colorClass: 'bg-red-500',
          icon: ShoppingCartIcon
        },
        {
          title: 'Send a Coupon',
          link: '/merchant/send-coupon',
          subtitle: 'Buy a coupon for a consumer',
          colorClass: 'bg-purple-500',
          icon: PaperAirplaneIcon,
        },
        {
          title: 'Scan a Coupon',
          link: '/merchant/scan-coupon',
          subtitle: 'Scan a QR code in order to redeem',
          colorClass: 'bg-black',
          icon: QrCodeIcon,
        },
        {
          title: 'Talk to Customers',
          link: '/merchant/talk',
          subtitle: 'Communicate with your customers',
          colorClass: 'bg-blue-500',
          icon: ChatBubbleBottomCenterTextIcon,
        },
        {
          title: 'Transaction History',
          link: '/merchant/tx',
          subtitle: 'View all previous transactions',
          colorClass: 'bg-gray-500',
          icon: QueueListIcon
        },
      ],
    }
  },
  components: {
    MenuListItem,
  },
}
</script>
