<template>
  <div>
    <h3 class="text-lg font-medium leading-6 text-gray-900">Talk to Consumers</h3>
    <p class="mt-1 max-w-2xl text-sm text-gray-500">Send a message to all your consumers - attachment optional</p>
  </div>

  <div class="flex items-start space-x-4 pt-4 ">
    <div class="flex-shrink-0">
      <img class="inline-block h-10 w-10 rounded-full" src="../../assets/default-avatar.png" alt="">
    </div>
    <div class="min-w-0 flex-1">
      <form action="#" class="relative">
        <div class="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
          <label for="comment" class="sr-only">Add your comment</label>
          <textarea rows="3" name="comment" id="comment" class="block w-full resize-none border-0 py-3 focus:ring-0 sm:text-sm" placeholder="Message to be sent to consumers..."></textarea>

          <!-- Spacer element to match the height of the toolbar -->
          <div class="py-2" aria-hidden="true">
            <!-- Matches height of button in toolbar (1px border + 36px content height) -->
            <div class="py-px">
              <div class="h-9"></div>
            </div>
          </div>
        </div>

        <div class="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
          <div class="flex items-center space-x-5">
            <div class="flex items-center">
              <button type="button" class="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">
                <!-- Heroicon name: mini/paper-clip -->
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z" clip-rule="evenodd" />
                </svg>
                <span class="sr-only">Attach a file</span>
              </button>
            </div>
          </div>

          <div class="flex-shrink-0">
            <button type="button"
                    class="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Post</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="mt-1 flex justify-center sm:col-span-2 py-4">
    <router-link :to="this.$store.state.home">
      <button type="button"
              class="inline-flex justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">
        Go back
      </button>
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'TalkToCustomersView',
}
</script>
