<template>
  <h2 class="text-lg font-medium text-gray-900">SupaCoupon Consumer</h2>
  <p class="mt-1 text-sm text-gray-500">Select an option from the menu below.</p>

  <ul role="list"
      class="mt-6 divide-y divide-gray-200 border-t border-b border-gray-200">
    <MenuListItem v-for="(mli, index) of menuListItems"
                  :key="index"
                  :title="mli.title"
                  :subtitle="mli.subtitle"
                  :link="mli.link"
                  :colorClass="mli.colorClass">
      <template #icon>
        <component :is="mli.icon" class="h-6 w-6 text-white"/>
      </template>
    </MenuListItem>
  </ul>

  <div class="mt-6 flex">
    <router-link to="/tell" class="text-sm font-medium text-indigo-600 hover:text-indigo-500">
      Tell someone about SupaCoupon
      <span aria-hidden="true"> &rarr;</span>
    </router-link>
  </div>
</template>

<script>
import {DocumentMagnifyingGlassIcon, QueueListIcon, ShoppingCartIcon, TicketIcon} from "@heroicons/vue/20/solid";
import MenuListItem from "@/components/MenuListItem";

export default {
  name: 'ConsumerView',
  data() {
    return {
      menuListItems: [
        {
          title: 'Buy a Coupon',
          link: '/consumer/buy-coupon',
          subtitle: 'Buy and send a redeemable coupon',
          colorClass: 'bg-red-500',
          icon: ShoppingCartIcon
        },
        {
          title: 'Request a Coupon',
          link: '/consumer/request-coupon',
          subtitle: 'Request a coupon paid by someone else',
          colorClass: 'bg-purple-500',
          icon: TicketIcon
        },
        {
          title: 'View my Coupons',
          link: '/consumer/view-coupons',
          subtitle: 'View coupons to approve & redeem',
          colorClass: 'bg-yellow-500',
          icon: DocumentMagnifyingGlassIcon
        },
        {
          title: 'Transaction History',
          link: '/consumer/tx',
          subtitle: 'View all previous transactions',
          colorClass: 'bg-gray-500',
          icon: QueueListIcon
        },
      ],
    }
  },
  components: {
    MenuListItem,
  },
}
</script>
