<template>
  <router-link :to="link">
    <li class="active:bg-gray-100">
      <div class="group relative flex items-start space-x-3 py-4">
        <div class="flex-shrink-0">
          <span class="inline-flex items-center justify-center h-10 w-10 rounded-lg" :class="colorClass">
            <slot name="icon"></slot>
          </span>
        </div>
        <div class="min-w-0 flex-1">
          <div class="text-sm font-medium text-gray-900">
            <a href="#">
              <span class="absolute inset-0" aria-hidden="true"></span>
              {{ title }}
            </a>
          </div>
          <p class="text-sm text-gray-500">{{ subtitle }}</p>
        </div>
        <div class="flex-shrink-0 self-center">
          <slot name="nav-icon">
            <ChevronRightIcon class="h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
          </slot>
        </div>
      </div>
    </li>
  </router-link>
</template>

<script>

import {ChevronRightIcon} from "@heroicons/vue/20/solid";

export default {
  name: 'MenuListItem',
  components: {ChevronRightIcon},
  props: ['title', 'subtitle', 'colorClass', 'link']
}
</script>

<style scoped>
</style>

