<template>
  <div class="mt-1 flex justify-center sm:col-span-2 py-4">
    <router-link :to="this.$store.state.home">
      <button type="button"
              class="inline-flex justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">
        Go back
      </button>
    </router-link>
  </div>
</template>
