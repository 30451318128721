<template>
  <h3 class="text-lg font-medium leading-6 text-gray-900">View Coupons</h3>
  <p class="mt-1 max-w-2xl text-sm text-gray-500">You can approve or redeem your coupons from here</p>

  <div class="my-2 -mx-4 overflow-x-auto ">
    <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
      <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <table class="min-w-full table-fixed divide-y divide-gray-300">
          <thead class="bg-gray-50">
          <tr>
            <th scope="col"
                class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6">Code
            </th>
            <th scope="col" class="py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
              Merchant
            </th>
            <th scope="col" class="relative py-3 pl-3 pr-4 sm:pr-6">
              <span class="sr-only">Action</span>
            </th>
          </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
          <tr v-for="item in items" :key="item._id">
            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
              {{ item.couponCode }}
              <span class="font-normal">
              <br/>
              {{ item.couponStatus }}
                <span v-if="item.couponExpiryDate"><br/>Expiry: {{ item.couponExpiryDate }}</span>
              <br/>
              R{{ item.couponValue }}
            </span>
            </td>
            <td class="py-4 whitespace-pre-wrap text-sm text-gray-500 truncate" style="max-width:150px">
              {{ item.merchantCode }}
            </td>
            <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
              <a @click="action(item)" class="text-indigo-600 hover:text-indigo-900 cursor-pointer">{{
                  actionTitle(item)
                }}<span
                    class="sr-only"></span></a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <GoBack/>
</template>

<script>

import api from "@/api/api";
import store from "@/store";
import GoBack from "@/components/GoBack";
import router from "@/router";

export default {
  name: 'ViewCouponsView',
  components: {GoBack},
  data() {
    return {
      items: []
    }
  },
  computed: {},
  methods: {
    action(item) {
      if (item.couponStatus !== 'Active')
        return

      api.post(`/rest/mezzanine-hackathon/v1/coupons/coupon/redeem?`
          + `couponCode=${item.couponCode}&benefitName=${item.benefitName}&redemptionPointName=${item.merchantCode}&`
          + `redemptionValue=${item.couponValue}&redemptionDateTime=2022-10-27 12:00:00`
      ).then(r => {
        console.log(r)
        store.state.notification.show = true
        store.state.notification.title = 'Success'
        store.state.notification.message = r.data.confirmationMessage
        // item.couponStatus = 'Redeemed'
        // item.couponExpiryDate = ''
        return router.push(store.state.home)
      }).catch(() => {
        store.state.notification.show = true
        store.state.notification.title = 'Error'
        store.state.notification.message = 'Ensure form is filled out'
      })
    },
    actionTitle(item) {
      if (item['couponStatus'] === 'Requested')
        return 'Approve'
      else return 'Redeem'
    },
  },
  async created() {
    const mobileNumber = store.state.msisdn
    const location = 'test'
    api.get(`/rest/mezzanine-hackathon/v1/coupons/person/coupons?mobileNumber=${mobileNumber}&locationName=${location}`)
        .then(r => {
          this.items = r.data.filter(i => i.couponStatus !== 'Redeemed')
        })
  }
}
</script>
