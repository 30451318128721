<template>
  <h2 class="pt-5 text-center text-lg font-medium text-gray-900">In order to use SupaCoupon, scan this QR code using
    VodaPay:</h2>
  <img class="mt-10 mb-10 mx-auto w-1/2 object-cover"
       src="../assets/img.png"
       alt="QR code">
  <div class="mt-1 flex justify-center sm:col-span-2 py-4">
    <router-link :to="this.$store.state.home">
      <button type="button"
              class="inline-flex justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">
        Go back
      </button>
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'TellSomeoneView',
}
</script>
