import axios from 'axios'

const axiosInstance = axios.create({
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': 'Basic Mjc4MzU5MTIzNjk6c2FtczEyMw==',
    },
})

axiosInstance.getBenefits = function (mobileNumber, locationName) {
    return axiosInstance.get(`/rest/mezzanine-hackathon/v1/coupons/benefits`
        // + `?mobileNumber=${mobileNumber}&locationName=${locationName}`)
        + `?mobileNumber=${mobileNumber}&locationName=${locationName}&redemptionPointName=Hassim Hardware`)
}

axiosInstance.getRedemptionPoints = function (mobileNumber, locationName, benefitName) {
    return axiosInstance.get(`/rest/mezzanine-hackathon/v1/coupons/redemptionpoints`
        + `?mobileNumber=${mobileNumber}&locationName=${locationName}&benefitName=${benefitName}`)
}

export default axiosInstance
