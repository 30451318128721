<template>
  <h2 class="pt-5 text-center text-lg font-medium text-gray-900">Scan this QR code using VodaPay:</h2>
  <p class="mt-1 text-center max-w-2xl text-sm text-gray-500 mx-auto">
    Scanning a QR code on the consumer’s phone, takes you to a Redeem a coupon page, with details
    prepropulated.
  </p>
  <img class="mt-10 mb-10 mx-auto w-1/2 object-cover"
       src="../../assets/img.png"
       alt="QR code">
  <div class="mt-1 flex justify-center sm:col-span-2 py-4">
    <GoBack/>
  </div>
</template>

<script>

import GoBack from "@/components/GoBack";

export default {
  name: 'ScanCouponView',
  components: {GoBack},
}
</script>
