<template>
  <div>
    <h3 class="text-lg font-medium leading-6 text-gray-900">View Transaction History</h3>
    <p class="mt-1 max-w-2xl text-sm text-gray-500">Audit trail of historic transactions with status</p>
  </div>

  <div class="my-2 -mx-4 overflow-x-auto ">
    <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
      <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <table class="min-w-full table-fixed divide-y divide-gray-300">
          <thead class="bg-gray-50">
          <tr>
            <th scope="col"
                class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6">
              Voucher Detail
            </th>
            <th scope="col" class="py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
              Merchant
            </th>
            <th scope="col" class="py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">
              Date
            </th>
          </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
          <tr v-for="item in items" :key="item._id">
            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
              {{ item.couponCode }}
              <span class="font-normal">
              <br/>
              {{ item.couponStatus }} (Exp: {{ item.couponExpiryDate }})
              <br/>
              {{ item.couponValue }}
            </span>
            </td>
            <td class="py-4 whitespace-pre-wrap text-sm text-gray-500 truncate" style="max-width:150px">
              {{ item.merchantCode }}
            </td>
            <td class="py-4 whitespace-pre-wrap text-sm text-gray-500 truncate" style="max-width:150px">
              {{ item.merchantCode }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <GoBack/>
  </div>

  <h3 class="mt-2 mb-2 text-lg font-medium leading-6 text-gray-900">Analytics</h3>
  <p class="mt-1 max-w-2xl text-sm text-gray-500">Business Intelligence Dashboard powered by Tableau</p>
  <img class="mt-5 mx-auto w-full object-cover"
       src="../../assets/tableau-sample.png"
       alt="Tableau">
  <img class="mt-0 mb-5 mx-auto w-full object-cover"
       src="../../assets/tableau-sample-2.png"
       alt="Tableau 2">
</template>

<script>

import GoBack from "@/components/GoBack";
import store from "@/store";
import api from "@/api/api";

export default {
  name: 'MerchantTxView',
  components: {GoBack},
  data() {
    return {
      items: [],
    }
  },
  methods: {},
  async created() {
    const mobileNumber = store.state.msisdn
    const location = 'test'
    api.get(`/rest/mezzanine-hackathon/v1/coupons/person/coupons?mobileNumber=${mobileNumber}&locationName=${location}`)
        .then(r => {
          this.items = r.data.filter(i => i.couponStatus !== 'Active' && i.couponStatus !== 'Requested')
        })
  }
}
</script>
