import {createStore} from 'vuex'

export default createStore({
    state: {
        home: '/',
        name: '',
        notification: {
            show: false,
            title: '',
            message: '',
        }
    },
    getters: {
        msisdn() {
            // Populated by Mini app in index.html
            return document.getElementById("username").innerHTML
        },
    },
    mutations: {},
    actions: {},
    modules: {}
})
