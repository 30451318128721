<template>
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-if="showModal">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
        <div
            class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-sm sm:p-6">
          <div>
            <div v-if="title==='Success'"
                 class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
              <CheckIcon class="h-6 w-6 text-green-600"/>
            </div>
            <div v-else class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
              <XCircleIcon class="h-6 w-6 text-red-600"/>
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-lg font-medium leading-6 text-gray-900" id="modal-title">{{ title }}</h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">{{ message }}</p>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6">
            <button type="button" @click="hideModal()" class="inline-flex w-full justify-center rounded-md border border-transparent
            bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700
            focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm">Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <img class="h-20 w-full object-cover lg:h-48"
         src="./assets/hackathon-bg.jpg"
         alt="Hackathon">
    <span id="username" ref="username"
          class="absolute top-0 right-0 pt-4 pr-4 text-white font-medium text-sm flex flex-col">
      <span class="inline-flex text-end">
      {{ name }}
        <br/>
        {{ msisdn }}
      </span>
    </span>
    <div class="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
      <div class="-mt-12 mb-4 sm:-mt-16 sm:flex sm:items-center sm:space-x-2">
        <div class="flex">
          <img v-if="isMerchant" class="h-20 w-20 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
               src="./assets/default-avatar-merchant.png"
               alt="">
          <img v-else class="h-20 w-20 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
               src="./assets/default-avatar.png"
               alt="">
        </div>
      </div>
      <router-view/>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import {CheckIcon, XCircleIcon} from "@heroicons/vue/20/solid";
import api from "@/api/api";

export default {
  name: 'App',
  components: {CheckIcon, XCircleIcon},
  computed: {
    msisdn() {
      return store.state.msisdn
    },
    title() {
      return store.state.notification.title
    },
    message() {
      return store.state.notification.message
    },
    showModal() {
      return store.state.notification.show
    },
    isMerchant() {
      return store.state.home === '/merchant'
    },
  },
  data() {
    return {
      name: '',
    }
  },
  methods: {
    hideModal() {
      store.state.notification.show = false
    },
    mockNavigate(msisdn) {
      if (msisdn.startsWith('2773'))
        return '/merchant'
      else
        return '/consumer'
    },
    async getUserProfile() {
      const mobileNumber = store.state.msisdn
      api.get(`/rest/mezzanine-hackathon/v1/coupons/person/profile?mobileNumber=${mobileNumber}`)
          .then(r => {
            if (r.data.redemptionPointName) {
              store.state.name = r.data.redemptionPointName
              this.name = `🛠 ${r.data.redemptionPointName}`.toUpperCase()
            } else {
              this.name = `${r.data.personName} ${r.data.personSurname}`.toUpperCase()
              store.state.name = this.name
            }
          })
    },
  },
  mounted() {
    const findMyHome = this.mockNavigate
    const getUserProfile = this.getUserProfile
    setTimeout(function () {
      store.state.msisdn = window.msisdn
      store.state.home = findMyHome(store.state.msisdn)
      getUserProfile().then(() => router.push(`${store.state.home}`))

    }, 500)
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
