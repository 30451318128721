<template>
  <div>
    <h3 class="text-lg font-medium leading-6 text-gray-900">Redeem a Coupon</h3>
    <p class="mt-1 max-w-2xl text-sm text-gray-500">Fill in the form below in order to buy a coupon and send to a
      number.</p>
  </div>
  <div class="mt-2 space-y-2 sm:space-y-5">
    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
      <label for="merchant" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Merchant</label>
      <div class="mt-1 sm:col-span-2 sm:mt-0">
        <input type="text" name="merchant" id="merchant" v-model="form.merchant" readonly
               class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm bg-gray-100 sm:max-w-xs sm:text-sm">
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
      <label for="beneficiary" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Beneficiary</label>
      <div class="mt-1 sm:col-span-2 sm:mt-0">
        <input type="text" name="beneficiary" id="beneficiary" v-model="form.beneficiary"
               class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm">
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
      <label for="couponCode" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Coupon Code</label>
      <div class="mt-1 sm:col-span-2 sm:mt-0">
        <input type="text" name="couponCode" id="couponCode" v-model="form.couponCode"
               class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm">
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
      <label for="benefit" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Benefit</label>
      <div class="mt-1 sm:col-span-2 sm:mt-0">
        <select id="benefit" name="benefit"
                v-model="form.benefit"
                class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm">
          <option v-for="(m, index) in benefits" :key="index" :value="form.benefit">
            {{ m }}
          </option>
        </select>
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
      <label for="availableValue" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Available
        Value</label>
      <div class="relative mt-1 rounded-md shadow-sm">

        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span class="text-gray-500 sm:text-sm">R</span>
        </div>
        <input type="number" name="availableValue" id="availableValue"
               v-model="form.availableValue"
               class="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
               aria-describedby="price-currency">

        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span class="text-gray-500 sm:text-sm" id="price-currency">ZAR</span>
        </div>
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
      <label for="redemptionValue" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Redemption
        Value</label>
      <div class="relative mt-1 rounded-md shadow-sm">

        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span class="text-gray-500 sm:text-sm">R</span>
        </div>
        <input type="number" name="redemptionValue" id="redemptionValue"
               v-model="form.redemptionValue"
               class="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
               aria-describedby="price-currency">

        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span class="text-gray-500 sm:text-sm" id="price-currency">ZAR</span>
        </div>
      </div>

      <div class="mt-1 flex justify-end sm:col-span-2 py-4">
        <router-link :to="this.$store.state.home">
          <button type="button"
                  class="inline-flex justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">
            Cancel
          </button>
        </router-link>
        <button type="submit"
                @click="save"
                class="ml-5 inline-flex justify-center rounded-md border border-transparent bg-sky-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2">
          Redeem Coupon
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import router from "@/router";
import store from "@/store";
import api from "@/api/api";

export default {
  name: 'RedeemCouponView',
  data() {
    return {
      redemptionPoints: [],
      benefits: [],
      form: {
        merchant: store.state.name,
        beneficiary: '',
        couponCode: '',
        benefit: '',
        availableValue: '180',
        redemptionValue: '0',
      }
    }
  },
  async created() {
    api.getBenefits(store.state.msisdn, 'test').then(r => {
      r.data.forEach(rp => this.benefits.push(rp.benefitName))
      this.form.benefit = this.benefits[0]
    })
  },
  methods: {
    async save() {
      const form = this.form

      api.post(`/rest/mezzanine-hackathon/v1/coupons/coupon/redeem?`
          + `couponCode=${form.couponCode}&benefitName=${form.benefit}&redemptionPointName=${form.merchant}&`
          + `redemptionValue=${form.redemptionValue}&redemptionDateTime=2022-10-27 12:00:00`
      ).then(r => {
        console.log(r)
        if (r.data.confirmationMessage === 'Not found.') {
          store.state.notification.show = true
          store.state.notification.title = `Coupon Code ${r.data.confirmationMessage}`
          store.state.notification.message = 'Are you sure the code was entered correctly?'

        } else {
          store.state.notification.show = true
          store.state.notification.title = 'Success'
          store.state.notification.message = r.data.confirmationMessage
          return router.push(store.state.home)
        }
      }).catch(() => {
        store.state.notification.show = true
        store.state.notification.title = 'Error'
        store.state.notification.message = 'Ensure form is filled out'
      })
    },
  }
}
</script>
